import React from 'react';
import axios from 'axios';
import sampleCard from '../Sample/sampleCard.js';
import sampleCardEmpty from '../Sample/sampleCardEmpty.js';
import './card.css';

import DropMenuEmployee from '../Component/DropMenuEmployee.js';
import dayjs from 'dayjs';

function CardView() {
  const [cardTable, setCardTable] = React.useState(sampleCardEmpty);
  const [emp, setEmp] = React.useState(); //{id, name}

  const handleClick = (a,b) => (e) => {

  };

  const tableList = (card) => {
    let content = [];
    let i = 0;
    let s = card[0].start;
    let e = card[card.length - 1].start;
    let no = 1;

    const alpha = (day, basket) => {
      let content2 = [];

      let v = 0;
      for (let i = 0; i < day.length; i++) {
        if (day[i]?.basket === basket) {
          content2.push(
            <td >{dayjs(day[i].clock * 1000).format("HH:mm")}</td>
            //dayjs(item.start * 1000).format("h:mm A")
          );
          v = 1;
        }
      }
      if (v === 0) {
        content2.push(<td></td>);
      }
      return content2;
    };
    while (s <= e) {
      const item = card[i];

      let dayOfWeek = dayjs(s * 1000).format('dd');
      if (dayOfWeek !== "Fr" && dayOfWeek !== "Sa" && dayOfWeek !== "Su") {
        dayOfWeek = "";
      }

      if (item.start === s && item.day != null) {
        content.push(
          <tr>
            <td key={item.id}>{no} {dayOfWeek}</td>
            {alpha(item.day, 'A')}
            {alpha(item.day, 'B')}
            {alpha(item.day, 'C')}
            {alpha(item.day, 'D')}
            {alpha(item.day, 'E')}
            {alpha(item.day, 'F')}
          </tr>
        );
        i++;
      } else {
        //insert empty rows without i++;
        content.push(
          <tr>
            <td>{no} {dayOfWeek}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        );
      }
      s = s + (60 * 60 * 24);
      no++;

    }

    return content;

  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.post('https://ssabacus.duckdns.org/api/ops.php',
          { header: 'CARD_VIEW', emp_id: emp.id, level: 1 },
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              'cache-control': 'no-cache',
            }
          });
        setCardTable(result.data);
        //setCardTable(sampleCard);
      } catch (error) {
        setCardTable(sampleCard);
        console.log(error);
        //ALERT
      }
    }
    fetchData();
  }, [emp]);


  return (
    <>
      <h1>Card View</h1>
      <DropMenuEmployee setEmployee={setEmp} />
      <br></br>
      emp_id : {JSON.stringify(emp)}
      <br></br>

      <table>
        <thead>
          <tr>
            <th>
              No
            </th>
            <th>
              A
            </th>
            <th>
              B
            </th>
            <th>
              C
            </th>
            <th>
              D
            </th>
            <th>
              E
            </th>
            <th>
              F
            </th>

          </tr>
        </thead>
        <tbody>
          {
            tableList(cardTable)
          }
        </tbody>
      </table>
      <h2>XX</h2>
      {
        cardTable[0].day[0].id
      }
      <h2>XX</h2>
      {JSON.stringify(cardTable)}
      <br></br>


    </>
  );
}

export default CardView;