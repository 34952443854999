const sampleCard = [
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667232000,
      "day":[
         {
            "id":220,
            "basket":"D",
            "clock":1667291350
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667318400,
      "day":[
         {
            "id":221,
            "basket":"C",
            "clock":1667364282
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667491200,
      "day":[
         {
            "id":222,
            "basket":"D",
            "clock":1667550420
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667577600,
      "day":[
         {
            "id":225,
            "basket":"A",
            "clock":1667605223
         },
         {
            "id":226,
            "basket":"B",
            "clock":1667620517
         },
         {
            "id":227,
            "basket":"C",
            "clock":1667623508
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667750400,
      "day":[
         {
            "id":230,
            "basket":"A",
            "clock":1667778161
         },
         {
            "id":485,
            "basket":"B",
            "clock":1667793315
         },
         {
            "id":570,
            "basket":"C",
            "clock":1667796208
         },
         {
            "id":571,
            "basket":"D",
            "clock":1667809612
         },
         {
            "id":575,
            "basket":"F",
            "clock":1667823918
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667836800,
      "day":[
         {
            "id":577,
            "basket":"A",
            "clock":1667864456
         },
         {
            "id":578,
            "basket":"B",
            "clock":1667879732
         },
         {
            "id":579,
            "basket":"C",
            "clock":1667882814
         },
         {
            "id":580,
            "basket":"D",
            "clock":1667896011
         },
         {
            "id":582,
            "basket":"F",
            "clock":1667910303
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1667923200,
      "day":[
         {
            "id":764,
            "basket":"B",
            "clock":1667966095
         },
         {
            "id":765,
            "basket":"C",
            "clock":1667969136
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668009600,
      "day":[
         {
            "id":767,
            "basket":"A",
            "clock":1668037257
         },
         {
            "id":768,
            "basket":"B",
            "clock":1668052514
         },
         {
            "id":770,
            "basket":"C",
            "clock":1668055571
         },
         {
            "id":771,
            "basket":"D",
            "clock":1668068802
         },
         {
            "id":774,
            "basket":"E",
            "clock":1668069953
         },
         {
            "id":776,
            "basket":"F",
            "clock":1668083114
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668096000,
      "day":[
         {
            "id":780,
            "basket":"B",
            "clock":1668138953
         },
         {
            "id":782,
            "basket":"D",
            "clock":1668155152
         },
         {
            "id":783,
            "basket":"E",
            "clock":1668156309
         },
         {
            "id":785,
            "basket":"F",
            "clock":1668169524
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668182400,
      "day":[
         {
            "id":786,
            "basket":"A",
            "clock":1668209888
         },
         {
            "id":787,
            "basket":"B",
            "clock":1668225298
         },
         {
            "id":788,
            "basket":"C",
            "clock":1668228265
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668355200,
      "day":[
         {
            "id":791,
            "basket":"A",
            "clock":1668382887
         },
         {
            "id":792,
            "basket":"B",
            "clock":1668398200
         },
         {
            "id":793,
            "basket":"C",
            "clock":1668401095
         },
         {
            "id":794,
            "basket":"D",
            "clock":1668414323
         },
         {
            "id":796,
            "basket":"E",
            "clock":1668415548
         },
         {
            "id":797,
            "basket":"F",
            "clock":1668428700
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668441600,
      "day":[
         {
            "id":799,
            "basket":"A",
            "clock":1668469229
         },
         {
            "id":800,
            "basket":"B",
            "clock":1668484559
         },
         {
            "id":801,
            "basket":"C",
            "clock":1668487571
         },
         {
            "id":802,
            "basket":"D",
            "clock":1668500775
         },
         {
            "id":803,
            "basket":"E",
            "clock":1668501979
         },
         {
            "id":804,
            "basket":"F",
            "clock":1668515127
         }
      ]
   },
   {
      "emp_id":6,
      "name":"GINO",
      "start":1668528000,
      "day":[
         {
            "id":806,
            "basket":"A",
            "clock":1668555563
         },
         {
            "id":807,
            "basket":"B",
            "clock":1668570921
         }
      ]
   }
];

export default sampleCard;