import * as React from 'react';
import axios from 'axios';
//DROPDOWN MENU
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

/*
COMPONENT: DropMenuVessel
RETURN: a ROW of vessel selected by USER from fetched list (from SQL)

p.header:: optional HEADER
p.updateTrigger:: updateTrigger for the Vessel List (refetch from SQL)
p.setShowDel:: optional prop which may not be passed
p.setVessel:: sets ROW of vessel fetched from SQL (RETURN)
*/

function DropMenuEmployee(p) {

  const [empList, setEmpList] = React.useState([{}]);
  const menuList = Object.entries(empList).map((row) => {
    return (
      <MenuItem value={row[0]}>
        {row[1].name}
      </MenuItem>
    )
  });

  const handleSelect = (e) => {
    p.setEmployee(empList[e.target.value]);
  }


  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.post('https://ssabacus.duckdns.org/api/ops.php',
          { header: 'EMPLOYEE_LIST' },
          {
            headers: {
              'content-type': 'application/json; charset=utf-8',
              'cache-control': 'no-cache',
            }
          });
          setEmpList(result.data);
      } catch (error) {
        setEmpList([{ id: 10, name: "BEE" }, { id: 11, name: "AYE" }]);
        console.log(error);
        //ALERT
      }
    }
    fetchData();
  }, [p.updateTrigger]);
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, float: 'left' }}>
      <InputLabel>Employees</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        onChange={handleSelect}
        label="Employees"
      >
        {menuList}

      </Select>
    </FormControl>
  );
}

export default DropMenuEmployee;