import React from 'react';
import Papa from "papaparse";
import axios from 'axios';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';

//const allowedExtensions = ["csv"];

function CSVParser() {
  const [userJSON, setUserJSON] = React.useState();
  const [uploadedCSV, setUploadedCSV] = React.useState(null);
  const [msg, setMsg] = React.useState("");

  const handleCSVSubmit = (e) => {
    e.preventDefault()

    Papa.parse(uploadedCSV, {
      header: true,
      complete: (results) => {
        console.log({ results })
        setUserJSON(results)
      },
    })
  }

  const handleCSVUpload = (e) => {
    //console.log(e.target.files[0]);
    setUploadedCSV(e.target.files[0]);
  }

  const handleSubmit = async (e) => {
    try {
      const result = await axios.post('https://ssabacus.duckdns.org/api/csv.php',
        userJSON,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            'cache-control': 'no-cache',
          }
        });
      setMsg(result.data);
      /*result.data returns 
      0 :: Vessel is not added into TOD
      due to INSERT IGNORE as compound vessel_id & wr_id is not unique
      1 :: Operation is sucessful
        */
      //TRIGGER TO UPDATE WORKREPORT table

    } catch (error) {
      console.log(error);
      //ALERT
    }
  };

  const handleBasket = () => {
    ;
  }


  return (
    <>
      <h1> hello world</h1>
      <input
        onChange={handleCSVUpload}
        id="csvInput"
        name="file"
        type="File"
      />
      <br></br>
      <br></br>
      <Button onClick={handleCSVSubmit}>Upload</Button>
      <br></br>
      <br></br>
      <Button onClick={handleSubmit}>Submit</Button>

      <br></br>
      <br></br>
      {
        //JSON.stringify(userJSON)
        //dayjs(1666627200 * 1000).format("DD MMM YYYY")
        dayjs(1671112744 * 1000).format("HH:mm:ss")
      }
      <br></br>
      MSG: <br></br>
      {JSON.stringify(msg)}


      <br></br>
      <Button onClick={handleBasket}>Set Baskets</Button>
    </>
  );
}

export default CSVParser;