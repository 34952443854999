const sampleCardEmpty = [
   {
      "emp_id":0,
      "name":"Empty",
      "start":1667232000,
      "day":[
         {
            "id":0,
            "basket":"A",
            "clock":1667232000
         }
      ]
   }
];

export default sampleCardEmpty;