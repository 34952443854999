import React from 'react';
import Button from '@mui/material/Button';
import './App.css';
import CSVParser from './CSV/CSVParser';
import CardView from './Attendance/CardView';
// /npm install papaparse


function App() {
  const [view, setView] = React.useState('card');
  const handleCardClick = (e) => {
    setView('card');
  };
  const handleParseClick = (e) => {
    setView('parser');
  };
  return (
    <>
      <h1>CSV</h1>
      <h2>{view}</h2>
      <Button onClick={handleParseClick}>CSV PARSER</Button>
      <br></br>
      <br></br>
      <Button onClick={handleCardClick}>Card View</Button>
      { view === 'parser' 
          ? <CSVParser></CSVParser>
          : <CardView></CardView>
      }
      


    </>

  );
}

export default App;
